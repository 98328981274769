.repush-btn-div{
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;
  }

  .formControl{
    width: 100%;
  }

  .padding-2{
    padding: 2rem;
  }

  .flex-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }